import React from 'react'
// import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import Reveal from 'react-reveal/Reveal';
import media from '../utils/styled';
import Layout from '../components/layout'
import Werk from '../components/werk'
import Ankuendigung from '../components/ankuendigung'
import Intro from '../components/intro'
import Uschi from '../components/uschi'
import Carousel from '../components/carousel'

const SquareImage = styled.div`
  width: calc(100% - var(--offset));
  background-color: #EEE;
  margin-left: ${props => (props.orientation === 'right' ? 'var(--offset)' : '0')};
  background-color: ${props => props.backgroundColor};
  margin-top: -200px;
  ${media.tablet`
    width: 420px;
  `}
  ${media.desktop`
    width: 420px;
  `}
`

const Space = styled.div`
  width: 100%;
  height: 32px;
`

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const query = graphql`
  query {
    eisbaer: file(relativePath: { eq: "u-maurer-eisbaer.jpg" }) {
      ...fluidImage
    },
    heissbaer: file(relativePath: { eq: "u-maurer-heissbaer.jpg" }) {
      ...fluidImage
    },
    weidepraemie: file(relativePath: { eq: "u-maurer-weidepraemie.jpg" }) {
      ...fluidImage
    },
    uschimaurer: file(relativePath: { eq: "uschi-maurer.jpg" }) {
      ...fluidImage
    },
    europarutschtvomstier: file(relativePath: { eq: "u-maurer-europarutschtvomstier.jpg" }) {
      ...fluidImage
    },
    kelandschaft: file(relativePath: { eq: "k-ewald-landschaft.jpg" }) {
      ...fluidImage
    },
    kestillleben: file(relativePath: { eq: "k-ewald-stillleben.jpg" }) {
      ...fluidImage
    },
    keselbstportrait: file(relativePath: { eq: "k-ewald-selbstportrait.jpg" }) {
      ...fluidImage
    },
    kaminhunde: file(relativePath: { eq: "u-maurer-kaminhunde.jpg" }) {
      ...fluidImage
    }
  }
`

const IndexPage = (props) => {

  const uschi = <Img imgStyle={{objectPosition: '75% center'}} fluid={props.data.uschimaurer.childImageSharp.fluid} />

  return (
  <Layout>
    <Intro>
      <p style={{'marginTop': '64px'}}>
        Das Atelier Wasserhaus ist der Arbeitsort der bildenden Künstlerin Uschi Maurer in Eßfeld, Giebelstadt. Hier finden Sie Informationen, ausgewählte Werke und aktuelle Austellungstermine. 
      </p>
    </Intro>
    <Reveal effect="fadeInUp">

    <Werk title="Kaminhunde" followedByImage backgroundColor="#E2C389">
      <p><strong>Kaminhunde</strong> · Bronze</p>
    </Werk>
    <SquareImage>
      <div style={{'marginTop': '-200px'}}>
        <div><Img fluid={props.data.kaminhunde.childImageSharp.fluid} /></div>
      </div>
    </SquareImage>

    <Ankuendigung>
        <p style={{display: 'none'}}>
          Für die Ausstellung <strong>Home, Sweet Home</strong> habe ich den Aspekt Haustiere aufgegriffen und zeige fünf Bronzen "von Hund bis Katz", die zum Teil überspitzt dargestellt sind.
          Einige ironische Hundeköpfe aus Keramik sind auch mit dabei.
        </p>
        <p>
          Für die Ausstellung <strong>Home, Sweet Home</strong> habe ich den Aspekt Haustiere aufgegriffen und zeige neben den <em>Kaminhunden</em> fünf Bronzen "von Hund bis Katz", die zum Teil überspitzt dargestellt sind.
          Einige ironische Hundeköpfe aus Keramik sind auch mit dabei.
        </p>
        <h3>
          Home sweet home<br/>
          1.-5. Mai 2019<br/>
          Altes Gefängnis Freising
        </h3>
        <p className="Ankuendigung-mehr">
          <a href="https://veranstaltungen.freising.de/freising/freisinger-gefaengnis-home-sweet-home-e638c00629cf482d1db927b80b00261c2.html">weitere Infos zur Ausstellung</a><br />
          <a href="http://www.kunstrefugium.de/index.php/aktuelles/188-home-sweet-home">Flyer</a>
        </p>
      </Ankuendigung>
      <div>
        <Space />
        <Werk title="Nachlass Kurt Ewald" followedByImage backgroundColor="#98ABB2">
          <p><strong>Kurt Ewald</strong> · 1928 – 2016</p>
        </Werk>
        <div style={{'marginTop': '-200px'}}>
          <Carousel grid="small">
            <div><Img fluid={props.data.kestillleben.childImageSharp.fluid} /></div>
            <div><Img fluid={props.data.keselbstportrait.childImageSharp.fluid} /></div>
            <div><Img fluid={props.data.kelandschaft.childImageSharp.fluid} /></div>
          </Carousel>
        </div>
      </div>

      <Ankuendigung>
        <p>
          Die Ausstellung <strong>Kurt Ewald · So war's</strong> zeigte den künstlerischen Nachlass und würdigte meinen verstorbenen Mann und sein umfangreiches Lebenswerk.
        </p>
        <h3>
          Kurt Ewald · So war's<br/>
          25.-28. Januar 2019<br/>
            Kartoffelkeller
            Giebelstadt
        </h3>
        <p className="Ankuendigung-mehr">
          <a href="http://www.kulturverein-giebelstadt.de/images/kulturverein/2018-1.jpg">weitere Infos zur Ausstellung</a>
        </p>
      </Ankuendigung>

      <div>
      <Werk title="Weideprämie" followedByImage backgroundColor="#D9C2AD" orientation="right">
        <p><strong>Weideprämie · </strong>Eine kleine Kuh auf einer Stele (echter Weidezaunpfahl) sieht auf den ersten Blick gefällig aus, genau wie die bayrischen Kühe auf der Weide einen idyllischen Eindruck vermitteln.</p>
        <p>Es ist aber längst nicht mehr selbstverständlich, dass Kuh und Weide zusammen gehören.</p>
        <p>Die wenigsten wissen, dass die bayrischen Kühe nur draußen stehen, weil es eine Weideprämie gibt. Diese setzt den Bauern bzw. den landwirtschaftlichen Betrieben einen finanziellen Anreiz  zur Erhaltung der Landschaft, vielleicht auch zum Kuhwohl -  und wir alle bezahlen dafür! Im Bayrischen Kulturlandschaftsprogramm (KULAP) ist unter dem Merkblatt B 60 die Sommerweidehaltung mit Weideprämie genaustens festgelegt.</p>
        <p>Die Weidezaunpfähle stammen aus einem Gebiet in Deutschland, wo es keine Weidehaltung mehr gibt.</p>
      </Werk>
      <SquareImage>
        <Img fluid={props.data.weidepraemie.childImageSharp.fluid} />
      </SquareImage>
    </div>
    <Ankuendigung>
      <p>
        Meine Arbeiten <em>Weidepremie</em> und <em>Europa rutscht vom Stier</em> waren in der Chiem-Art Galerie am Chiemsee ausgestellt.
      </p>
      <h3>
        Frauen - Glimmer, Glitzer und Gehörnte<br/>
        bis 3. März 2019<br/>
        Chiem-Art Galerie
      </h3>
      <p className="Ankuendigung-mehr">
        <a href=" https://www.chiemart-galerie.de/kunst-aktuell">weitere Infos zur Ausstellung</a>
      </p>
    </Ankuendigung>
    <Werk title="Europa rutscht vom Stier" followedByImage backgroundColor="#D4D4D4">
      <p><strong>Europa rutscht vom Stier · </strong>Scheitert der Gedanke einer starken und sicheren europäischen Union?</p>
      <p>Der Stier steht für die Kraft, die Europa zuverlässig trägt und zusammenhält. Doch er bäumt sich auf und sein Schritt geht ins Ungewisse.</p>
      <p>Europa rutscht, sie gerät in Schieflage, aber noch fällt sie nicht. So merken wir nicht wirklich, wohin Europa steuert. Schrecken wir erst auf, wenn es zum Sturz kommt - was unternehmen WIR dagegen?</p>
    </Werk>
    <SquareImage>
      <div style={{'marginTop': '-200px'}}>
        <div><Img fluid={props.data.europarutschtvomstier.childImageSharp.fluid} /></div>
      </div>
    </SquareImage>

      <div style={{'marginTop': '60px'}}>
        <Werk title="Eisbär - Heissbär · Diptychon" followedByImage backgroundColor="#CCE5E5" orientation="left">
          <p><strong>Eisbär - Heissbär</strong> · Diptychon</p>
        </Werk>
        <div style={{'marginTop': '-200px'}}>
          <Carousel>
            <div><Img fluid={props.data.eisbaer.childImageSharp.fluid} /></div>
            <div><Img fluid={props.data.heissbaer.childImageSharp.fluid} /></div>
          </Carousel>
        </div>
      </div>
      <Ankuendigung>
        <p>
          Der Kunstpreis der Stadt Marktheidenfeld wurde 2018 zum elften Mal ausgeschrieben und in der Technik Zeichnung vergeben. Das Thema lautete "Gedankenstrich".
          Ich war mit <em>Eisbär - Heissbär</em> dabei.
        </p>
        <h3>
          bis zum 30.12.<br/>
          Franck-Haus<br/>
          Marktheidenfeld
        </h3>
        <p className="Ankuendigung-mehr">
          <a href="https://www.stadt-marktheidenfeld.de/kultur-tourismus/kunstpreis-der-stadt/kunstpreis-2018">weitere Infos zu Ausstellung</a>
        </p>
      </Ankuendigung>
    <Uschi image={uschi} />
    </Reveal>
  </Layout>
  )
}

export default IndexPage

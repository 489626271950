import * as React from "react";
import styled from "styled-components";

const IntroContainer = styled.div`
  padding: 32px 16px;
  h3 {
    font-size: 32px;
    line-height: 48px;
    text-align: left;
  }
`

export default class Intro extends React.Component {

  render() {
    return (
      <IntroContainer>
        {this.props.children}
      </IntroContainer>
    );
  }
}

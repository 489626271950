import * as React from "react";
import styled from "styled-components";
import media from "../utils/styled";

const WerkContainer = styled.div`
  width: calc(100% - var(--offset));
  background-color: #EEE;
  padding: 16px;
  margin-left: ${props => (props.orientation === 'right' ? 'var(--offset)' : '0')};
  background-color: ${props => props.backgroundColor};
  padding-bottom: ${props => (props.followedByImage ? '200px' : '16px')};
  .gatsby-image-wrapper {
    margin-left: -16px;
    margin-right: -16px;
    margin-bottom: -16px;
    ${media.tablet`
      max-width: 50%;
    `}
  }
`

export default class Werk extends React.Component {

    // Set default properties
    static defaultProps = {
      text: "Hello World!",
      backgroundColor: '#CCC',
      orientation: 'left',
      followedByImage: false
    }

    render() {
      return (
        <WerkContainer followedByImage={this.props.followedByImage} orientation={this.props.orientation} backgroundColor={this.props.backgroundColor}>
          {this.props.children}
        </WerkContainer>
      );
    }
}

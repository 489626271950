import * as React from "react";
import styled from "styled-components";
import media from '../utils/styled';

const AnkuendigungContainer = styled.div`
  _background-color: #FFF;
  padding: 32px 16px;
  h3 {
    font-size: 32px;
    line-height: 48px;
    text-align: left;
    _margin-left: 96px;
  }
  .Ankuendigung-mehr {
    ${media.tablet`
      margin-top: -64px;
    `}
    text-align: right;
  }
  a {
    font-family: 'Josefin Sans', serif;
    color: #000;
    text-decoration: none;
  }
  a:before {
    content: '» ';
  }
`

export default class Ankuendigung extends React.Component {

  render() {
    return (
      <AnkuendigungContainer>
        {this.props.children}
      </AnkuendigungContainer>
    );
  }
}

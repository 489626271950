import * as React from "react";
import styled from "styled-components";
import media from '../utils/styled';

const CarouselContainer = styled.div`
  display: flex;
  margin: 0;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-points-x: repeat(100vw);
  scroll-snap-type: mandatory;
`
const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`
const CarouselSlot = styled.div`
  flex: 1 0 100%;
  flex-basis: calc(${(props) => props.base} - 2 * var(--offset));
  ${media.tablet`
    flex-basis: calc(33% - 2 * var(--offset));
  `}
  margin-right: 16px;
  margin-left: 16px;
  order: ${(props) => props.order};
  align-self: flex-end;
`

export default class Carousel extends React.Component {
  render() {
    const { grid, children } = this.props
  
    return (
      <div>
        <Wrapper>
          <CarouselContainer>
            { children.map((child, index) => (
              <CarouselSlot
                key={ index }
                order={ index }
                base={grid === 'small' ? '66%' : '100%'}
              >
                {child}
              </CarouselSlot>
            )) }
          </CarouselContainer>
        </Wrapper>
      </div>
    )
  }
}
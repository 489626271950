import * as React from "react";
import styled from "styled-components";
import media from '../utils/styled';

const UschiContainer = styled.div`
  width: calc(100% - var(--offset));
  background-color: #C3E6AA;
  padding: 16px;
  ${media.tablet`
    padding: 0 0 0 16px;
  `}
  margin-top: 32px;
  margin-left: ${props => (props.orientation === 'right' ? 'var(--offset)' : '0')};
  background-color: ${props => props.backgroundColor};
  padding-bottom: ${props => (props.followedByImage ? '240px' : '16px')};
  
  a {
    color: #000;
  }
  div.Uschi-content {
    ${media.tablet`
      display: flex;
      justify-content: space-between;
    `}
    .gatsby-image-wrapper {
      ${media.tablet`
        flex: 2;
        img {
          position: relative;
          left: -40px;
        }
      `}
      ${media.phoneExclusive`
        margin-left: -16px;
        margin-right: -16px;
        margin-bottom: -16px;
      `}
    }
    div.Uschi-vita {
      ${media.tablet`
        flex: 3;
        padding: 32px 32px 32px 0;
      `}
      h1 {
        margin: 16px 0 16px;
      }
      > ul {
        padding: 0 16px 0 0;
        list-style: none;
        margin: 0;
        li strong {
          font-weight: 400;
          font-style: italic;
        }
        ul.disc {
          list-style: disc;
          padding-left: 32px;
          margin-bottom: 32px;
          li {
            margin: 0
          }
        }
      }
    }
  }
`

export default class Uschi extends React.Component {

    // Set default properties
    static defaultProps = {
      text: "Hello World!",
      backgroundColor: '#CCC',
      orientation: 'left',
      followedByImage: false
    }

    render() {
      return (
        <UschiContainer orientation="right">
          <div className="Uschi-content">
            <div className="Uschi-vita">
            <h1>Uschi Maurer</h1>
            <ul>
              <li><strong>1969</strong> Geboren in Würzburg</li>
              <li><strong>1987</strong> Mitarbeit in einer Tierklinik</li>
              <li><strong>1988</strong> Fachabitur in Gestaltung</li>
              <li><strong>1993</strong> Gesellenprüfung als Keramikerin</li>
              <li><strong>1993 - 96</strong> Studium für Keramikgestaltung in Höhr-Grenzhausen</li>
              <li><strong>1996</strong> Abschlussarbeit in plastischen Arbeiten und Relief</li>
              <li><strong>1997</strong> Meisterprüfung für das Keramikhandwerk</li>
              <li><strong>ab 1997</strong> Eigenes Atelier und Werkstatt</li>
              <li>
                <p><strong>ab 2002</strong> Ateliergemeinschaft mit Ehemann Kurt Ewald in Giebelstadt bei Würzburg</p>
                <p>Arbeitsbereiche:</p>
                <ul className="disc">
                  <li>Bronzeskulpturen</li>
                  <li>Keramikskulpturen</li>
                  <li>Tierportraits</li>
                </ul>
                <ul>
                  <li><strong>Seit 1996</strong> Einzel- und Gruppenausstellungen im In- und Ausland</li>
                  <li><strong>2005</strong> Internationales Künstlersymposium Strassacker in Süssen</li>
                  <li><strong>Seit 2006</strong> Verschiedene öffentliche Aufträge, u.a. lebensgroße Bronzeskulpturen</li>
                </ul>
              </li>
              <li><a href="http://www.kunstrefugium.de">Mitglied im Kunstrefugium München e.V.</a> (<a href="http://www.kunstrefugium.de/index.php/kuenstler/182-maurer-uschi">Profil</a>)</li>
            </ul>
            </div>
            {this.props.image}
          </div>
        </UschiContainer>
      );
    }
}
